import React from "react";
import Layout from "../components/Layout";
import { Link, graphql } from "gatsby";
import "../scss/main.scss";

// descending order
function dateComparison(a, b) {
    const date1 = new Date(a.publishedDate)
    const date2 = new Date(b.publishedDate)
    
    return date2 - date1;
}


export default function Blogs({
    data: {
        allMarkdownRemark: { edges },
    },
}) {
    const blogs = edges.map((edge) => {
        const {
            node: {
                id,
                frontmatter: { title, year, date, slug, publishedDate },
            },
        } = edge;
        return {
            id,
            title,
            year,
            date,
            slug,
            publishedDate
        };
    }).sort(dateComparison);

    // group by year
    const articles = blogs.reduce((group, article) => {
        const { year } = article;
        group[year] = group[year] ?? [];
        group[year].push(article);
        return group;
    }, {})

    const articlesOrderDesc = Object.keys(articles).sort().reverse();

    return (
        <Layout pageTitle="Ikshana Insights | Powered by Ikshana Learning">
            <section className="hero about-hero-section">
                <h1 className="hero__title">Ikshana Insights</h1>
                <h3 className="blog__subtitle">Ignite Your Potential</h3>
            </section>
            <section className="container">
                <div className="blog__article">
                    {articlesOrderDesc.map((articleKey)=>(
                        <ul key={`year-${articleKey}`} className="blog__article__wrapper">
                            <h3 className="blog__year-label">{articleKey}</h3>
                            {articles[articleKey].map((article)=> (
                                <li key={article.id} className="blog__article__item">
                                    <Link to={article.slug} className="blog__link">
                                        {article.title}
                                    </Link>
                                    <div className="blog__date-label">{article.date}</div>
                                </li>
                            ))}
                        </ul>
                    ))
                    }
                </div>
            </section>
        </Layout>
    );
}

export const pageQuery = graphql`
  {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: " /(blogs)/" } }) {
      edges {
        node {
          id
          frontmatter {
            pageTitle
            slug
            title
            year
            date
            publishedDate
          }
        }
      }
    }
  }
`;
